/*** It looks like you're tech-savvy if you're looking through here. Have a portfolio you'd like an opinion on? Send it to hello@room9.design! ***/

const target1 = document.getElementById('line1');
const target2 = document.getElementById('line2');
const target3 = document.getElementById('line3');
const target4 = document.getElementById('ps');
const target5 = document.getElementById('menu-tab');
const target6 = document.getElementById('menu');

const menu = document.getElementById('menu');
const menuTab = document.getElementById('menu-tab');

const runAnimations = () => {
  setTimeout(() => {
    target1.classList.toggle('hide');
  }, 400);
  setTimeout(() => {
    target2.classList.toggle('hide');
  }, 1400);
  setTimeout(() => {
    target3.classList.toggle('hide');
  }, 2400);
  setTimeout(() => {
    target4.classList.toggle('hide');
  }, 3600);
  setTimeout(() => {
    target5.classList.toggle('hide');
  }, 5000);
  setTimeout(() => {
    target6.classList.toggle('hide');
  }, 5500);
};

let prevScrollpos = window.pageYOffset;
window.onscroll = () => {
  let currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    target5.style.right = undefined;
    target6.style.right = undefined;
  } else {
    target5.style.right = '-100px';
    target6.style.right = '-100px';
  }
  prevScrollpos = currentScrollPos;
};

const toggleMenu = (el) => {
  el.classList.toggle('menu-active');
};

menuTab.addEventListener('click', () => toggleMenu(menu));

runAnimations();